import React from 'react'

import { Table2 } from '../styledComponents'

const ChangeTable2 = ({ items }) => {

    if (!items) {
        return null;
    }
    const getRows = (items) => {
        return items.map((item, idx) => {
            //console.log(item)
            //const cl = '' + (item.description === 'coin' ? 'coin' : 'bill');
            const cl = '' + ((item.description.indexOf('bill') !== -1) ? 'bill' : 'coin');
            const value = Math.round(item.value / 100);
            return (
                <tr key={idx}>
                    <td><i className={cl}></i>{value}</td>
                    <td>
                        {item.count === 0 ? <img src='/images/warning-hight-icon.png' alt='' /> : item.count < 50 ? <img src='/images/warning-icon.png' alt='' /> : null}
                        {item.count}
                    </td>
                </tr>
            );
        });
    }

    return (
        <Table2>
            <thead>
                <tr>
                    <th>Номиналы/₽</th>
                    <th>Кол-во/шт.</th>
                </tr>
            </thead>
            <tbody>
                {getRows(items)}
            </tbody>
        </Table2>
    )
}

export default ChangeTable2
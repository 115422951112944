import styled, { css } from 'styled-components';

export const Content = styled.div``;

export const Buttons = styled.div`
  display: flex;
  .btn {
    margin-right: 20px;
  }
  margin: 0 0 30px;
`;

export const Reloader = styled.div`
  position: relative;
`;

export const Title = styled.div`
  margin: 0 0 1.3rem;
  font-size: 1.2rem;
  color: #fff;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-height: 580px;
`;

export const Left = styled.div`
  width: 500px;
  margin-right: 20px;
  img {
    pointer-events: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
  }
`;

export const Right = styled.div`
  width: 50%;
  color: #fff;
  position: relative;
  &.offline1::after,
  &.loading1::after,
  &.loading::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
  }
  &.loading1::after {
    background: #393e44;
  }
  &.offline1::before {
    content: 'Отключен';
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: normal;
    width: 240px;
    height: 50px;
    text-align: center;
    margin: -25px 0 0 -120px;
    z-index: 11;
  }
  &.loading1::before {
    content: '';
    z-index: 11;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(/images/loader.svg) no-repeat 50%;
    //background-size: 70px auto;
  }
`;

export const Limiter = styled.div`
  margin: -5px 0 30px;
  padding: 5px 0;
  position: relative;
  min-height: 60px;
  ${(props) =>
    props.loading &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(57, 62, 68, 0.8) url('/images/loader.svg') no-repeat 50% 50%;
        background-size: 30px auto;
      }
    `}
`;
export const LimiterItem = styled.div`
  margin: 0 0 7px;
  display: flex;
  align-items: center;
  label {
    cursor: pointer;
    margin: 0 0 0 10px;
    &.left-side{
      margin: 0 10px 0 0;
    }
  }
  input {
    font-size: 16px;
    padding: 3px 5px;
  }
  [type='checkbox'] {
    cursor: pointer;
    width: 17px;
    height: 17px;
  }
`;


export const Items = styled.div`
  margin: 0 0 30px;
`;

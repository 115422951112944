import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer as formReducer } from 'redux-form'

import thunk from 'redux-thunk'

import eventsReducer from './reducers/events'
import eventsHistoryReducer from './reducers/eventsHistory'
import windowsReducer from './reducers/windows'
import callsReducer from './reducers/calls'
import equipmentReducer from './reducers/equipment'
import widgetsReducer from './reducers/widgets'
import soundsReducer from './reducers/sounds'
import boardsReducer from './reducers/boards'

const reducers = combineReducers({
  boards: boardsReducer,
  equipment: equipmentReducer,
  events: eventsReducer,
  windows: windowsReducer,
  calls: callsReducer,
  widgets: widgetsReducer,
  sounds: soundsReducer,
  eventsHistory: eventsHistoryReducer,
  form: formReducer
});

const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(thunk)
));

export default store;

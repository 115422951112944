import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { openWindow } from '../../store/actions/windows';
import MapLocation from './LocationsMap';
import RacksMap from './RacksMap';
import ZoneInfo from '../shared/zone/ZoneInfo';
import VideoBlock from '../VideoBlock';

const MapWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

function ZonesMap(props) {
  function GetBoards() {
    const boards = window.config.objects ? window.config.objects.boards : [];
    if (!boards) {
      return null;
    }

    return boards.map((el, idx) => {
      //console.log('🚀 ~ returnboards.map ~ el', el);
      return (
        <ZoneInfo
          key={idx}
          top={el.top}
          left={el.left}
          zoneId={el.zoneId}
          color={el.color}
          number={0}
          onClick={() =>
            props.openWindow({
              id: '0',
              type: 'single_board',
              data: {
                zoneId: el.zoneId,
                color: el.color,
              },
            })
          }
        />
      );
    });
  }

  const nodes = window.config.nodes ? window.config.nodes : [];
  const cameraID = 'payment.01';
  let node;
  if (!nodes) {
    return null;
  }
  node = nodes[cameraID];

  return (
    <MapWrapper>
      <img src="/images/background2.png" alt="" />
      <VideoBlock cameraID={cameraID} node={node} />
      <MapLocation />
      <RacksMap />
      {GetBoards()}
    </MapWrapper>
  );
}

export default connect(null, {
  openWindow,
})(ZonesMap);

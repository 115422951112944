import {
  SET_EVENT,
  DEACTIVATE_EVENT,
  SET_EVENTS_LIST,
  DEACTIVATE_ALL_EVENTS,
  SET_EVENT_UNRECOGNIZED,
} from '../constants';

const test = false;
const testEvent = {
  "created_at": "2022-12-16T19:07:12.624606+03:00",
  "data": {
      "method": "controller.vehicle_plate_event",
      "replace": null,
      "stay_id": "0018ad1c-7d57-11ed-aed5-0e8b1da2deaa"
  },
  "id": "b38cc712-7d5b-11ed-b610-0e8b1da2deaa",
  "message": "ГРЗ отличается от недавно выехавшего на 1 символ",
  "parking_id": "000",
  "source": "lane-1-1",
  "source_name": "Стойка 01",
  "source_type": "rack.lane",
  "status": "active",
  "timestamp": "2022-12-16T19:07:11.621234+03:00",
  "type": "notification",
  "updated_at": "2022-12-16T19:07:12.624616+03:00"
};

const eventsReducer = (state, action) => {
  const defaultState = {
    events: {},
    unrecognized: test ? testEvent : null,
  };
  if (!state) {
    state = defaultState;
  }

  let newEvents = { ...state.events };

  switch (action.type) {
    case SET_EVENT:
      if (!newEvents[action.event.source]) {
        newEvents[action.event.source] = {};
      }
      newEvents[action.event.source][action.event.id] = action.event;
      return {
        ...state,
        events: newEvents,
      };

    case SET_EVENT_UNRECOGNIZED:
      return {
        ...state,
        unrecognized: action.event,
      };

    case DEACTIVATE_EVENT:
      delete newEvents[action.rackId][action.eventId];
      return {
        ...state,
        events: newEvents,
      };
    case DEACTIVATE_ALL_EVENTS:
      delete newEvents[action.rackId];
      return {
        ...state,
        events: newEvents,
      };
    case SET_EVENTS_LIST:
      return {
        ...state,
        events: action.data,
      };
    default:
      return state;
  }
};

export default eventsReducer;

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import PaymentMeanInfo from './PaymentMeanInfo';
import withRackLogic from '../withRackLogic';
import RadioButtonsPopup from '../RadioButtonsPopup';
import Clarification from '../Clarification';
import services from 'services';
import { GrayButton } from '../../../../shared/buttons';
import {
  RackWrapper,
  ReloadBlock,
  CallMakeButton,
  CallDropButton,
  Mode,
  ModeHeader,
  CurrentMode,
} from '../styledComponents';
import {
  ControlBlocks,
  StyledControlPanel,
  OpenGateButton,
  CloseGateButton,
  BlackButton,
  ControlBlockHeader,
  ControlBlock,
  ControlBlockButtons,
  BigOpenGateButton,
  PaymentMeanButtons,
} from './RackControl.styled';
import RackState from '../RackState';
import RackVideos from '../RackVideos';
import ConfirmWithComment from './ConfirmWithComment';

class RackControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModePopup: false,
      activeCam: '1',
      blocked: false,
      cams: null,
      showConfirm: false,
      action: () => '',
    };

    this.withCommentWhenOpen = window.dict['reasonofpass'] !== undefined;
  }

  componentDidMount = () => {
    this._ismounted = true;
    this.createtime = moment().unix();

    this.props.sendControlMsg('get_video_urls');
    window.socket.on('get_video_urls', (msg) => {
      //console.log('🚀 ~ get_video_urls rackcontrol', this.props.id, msg.target);
      if (!this._ismounted) {
        return;
      }
      if (msg.target === this.props.id) {
        this.setState({ cams: services.prepareVideoUrls(msg) });
      }
    });

    this.props.sendControlMsg('get_state');
  };

  componentWillUnmount = () => {
    this._ismounted = false;
  };

  printTicket = () => {
    let msg = {
      id: this.props.id,
      data: '{"message": "print ticket"}',
      user_id: window.user.getId(),
    };
    window.socket.emit('message', {
      jsonrpc: '2.0',
      id: '1',
      method: 'control.send',
      params: msg,
    });
  };

  /**
   * set new mode
   * @param {*} comment?
   */
  submitNewMode = (comment) => {
    const params = {
      mode: this.props.sharedState.newMode,
    };
    if (comment) {
      params.comment = comment;
    }
    this.props.sendControlMsg('set_mode', null, params);
  };

  /**
   * get current mode
   * @returns
   */
  getCurrentMode = () => {
    const { status } = this.props;
    const mode = status.mode;
    if (mode === 'emergency') {
      return 'заблокированный';
    } else if (mode === 'offline') {
      return 'отключен';
    } else if (mode === 'regular') {
      return 'штатный';
    } else if (mode === 'test') {
      return 'сервисный';
    } else if (mode === 'free') {
      return 'свободный';
    }
  };

  submitReload = () => {
    if (
      this.props.sharedState.reloadType === 'reboot controller' ||
      this.props.sharedState.reloadType === 'reboot lower controller' ||
      this.props.sharedState.reloadType === 'reboot upper controller'
    ) {
      this.props.setNotification('reload_controller_confirm');
    } else if (this.props.sharedState.reloadType === 'reboot gateway') {
      this.props.setNotification('reload_getaway_confirm');
    } else if (this.props.sharedState.reloadType === 'restart rfid') {
      this.props.sendControlMsg(this.props.sharedState.reloadType, () => this.props.setNotification('reload_help'));
    } else {
      this.props.sendControlMsg(this.props.sharedState.reloadType, this.props.toggleReloadPopup);
    }
  };

  toggleModePopup = () => {
    this.setState({ showModePopup: !this.state.showModePopup });
  };

  getReloadPopupButtons = () => {
    let buttons = [];

    buttons.push({
      msg: 'reboot_controller',
      func: this.props.setReloadType,
      text: 'Не работает контроллер',
    });
    if (this.props.data.barriersCount === 2) {
      buttons.push({
        msg: 'reboot_gateway',
        func: this.props.setReloadType,
        text: 'Не работает шлюз',
      });
    }
    buttons.push({
      msg: 'restart_rfid',
      func: this.props.setReloadType,
      text: 'Не может проехать по RFID',
    });
    return buttons;
  };

  getModePopupButtons = () => {
    const buttons = [{ msg: 'regular', func: this.props.setNewMode, text: 'Штатный' }];
    if (!services.isOperator()) {
      buttons.push({
        msg: 'emergency',
        func: this.props.setNewMode,
        text: 'Блокированный',
      });
      buttons.push({
        msg: 'free',
        func: this.props.setNewMode,
        text: 'Свободный',
      });
    }
    buttons.push({
      msg: 'test',
      func: this.props.setNewMode,
      text: 'Сервисный',
    });
    return buttons;
  };

  getClarifications = () => {
    return [
      {
        imgSrc: '/images/normal.png',
        name: 'Штатный',
        text: 'Работа устройства в соответствии с алгоритмами системы',
      },
      {
        imgSrc: '/images/blocked.png',
        name: 'Блокированный',
        text: 'Оборудование выключено, проезд закрыт, шлагбаум опущен',
      },
      {
        imgSrc: '/images/free.png',
        name: 'Свободный',
        text: 'Проезд открыт, шлагбаум поднят, для проезда ТС в экстренных случаях',
      },
      {
        imgSrc: '/images/service.png',
        name: 'Сервисный',
        text: 'Тестирование системы, шлагбаумы поднимаются при въезде автомобиля на индукционную петлю',
      },
    ];
  };

  unblock = () => {
    if (this.state.blocked) {
      return;
    }
    this.setState({ blocked: true }, () => {
      this.props.sendControlMsg('unblock');
      setTimeout(() => {
        this.setState({ blocked: false });
      }, 10000);
    });
  };

  /**
   * open gate with confirm and comment
   * @param {*} type
   */
  openWithConfirm = (type) => {
    if (!this.withCommentWhenOpen) {
      this.props.sendControlMsg(type);
      return;
    }

    this.setState({
      showConfirm: true,
      action: (comment) => {
        this.props.sendControlMsg(type, null, { comment });
      },
    });
  };

  /**
   * set rack mode with cofirm and comment
   */
  setMode = () => {
    if (!this.withCommentWhenOpen || this.props.sharedState.newMode !== 'free') {
      this.submitNewMode();
      this.toggleModePopup();
      return;
    }

    this.toggleModePopup();
    this.setState({
      showConfirm: true,
      action: (comment) => {
        this.submitNewMode(comment);
      },
    });
  };

  /**
   * close confirm
   */
  closeConfirm = () => {
    this.setState({ showConfirm: false });
  };

  render() {
    const { cams } = this.state;

    const rightCall = window.haveRight('mnemo.calls');
    const rightControl = window.haveRight('mnemo.rack_control');
    const { mode, with_print_receipt, with_allow_payment_mean } = this.props.status;
    const modeOk = mode;
    const offline = modeOk === 'offline';
    let cl = ''; //offline ? "offline" : "";
    let cl1 = offline ? 'offline' : '';
    let cl2 = offline ? 'offline1' : '';
    if (!modeOk) {
      cl += ' loading';
      cl1 += ' loading';
      cl2 += ' offline1';
    }
    //console.log('🚀 ~ render ~ this.props.dropCall:', this.props.dropCall);

    return (
      <RackWrapper className={cl}>
        <RackVideos cams={cams} id={this.props.id} cl={cl2} />
        <StyledControlPanel className={cl1}>
          {rightCall && <CallMakeButton action={this.props.makeCall} src="/images/audio.png" />}
          {rightCall && this.props.call && <CallDropButton action={this.props.cancelCall} src="/images/audio-2.png" />}

          <ReloadBlock>
            <PaymentMeanInfo single={true} id={this.props.id} sendControlMsg={this.props.sendControlMsg} />
            {(with_print_receipt || with_allow_payment_mean) && (
              <PaymentMeanButtons>
                {with_print_receipt && (
                  <GrayButton
                    disabled={!window.haveRight('mnemo.print_receipt')}
                    onClick={() => this.props.sendControlMsg('print_receipt')}
                  >
                    <img src="/images/ticket.png" alt="" />
                    Напечатать билет
                  </GrayButton>
                )}
                {with_allow_payment_mean && (
                  <GrayButton
                    disabled={!window.haveRight('mnemo.allow_payment_mean')}
                    onClick={() => this.props.sendControlMsg('allow_payment_mean')}
                  >
                    <img src="/images/ticket.png" alt="" />
                    Разрешить СО
                  </GrayButton>
                )}
              </PaymentMeanButtons>
            )}

            <GrayButton disabled={!rightControl} className={this.state.blocked ? 'loading' : ''} onClick={this.unblock}>
              <img src="/images/unlock.png" alt="" />
              Разблокировать оборудование
            </GrayButton>
            {rightControl && this.props.sharedState.showReloadPopup ? (
              <RadioButtonsPopup
                type={this.props.data.type}
                header="Укажите проблему"
                notification={this.props.sharedState.notification}
                sendControlMsg={this.props.sendControlMsg}
                submit={this.submitReload}
                currentState={this.props.sharedState.reloadType}
                togglePopup={this.props.toggleReloadPopup}
                buttons={this.getReloadPopupButtons()}
              />
            ) : null}
          </ReloadBlock>
          <Mode>
            <ModeHeader>
              <BlackButton disabled={!rightControl} onClick={this.toggleModePopup}>
                Режим работы
              </BlackButton>
              {this.state.showModePopup ? (
                <RadioButtonsPopup
                  header="Режимы работы"
                  currentState={this.props.sharedState.newMode}
                  submit={() => this.setMode()}
                  togglePopup={this.toggleModePopup}
                  buttons={this.getModePopupButtons()}
                />
              ) : null}
              <Clarification clarifications={this.getClarifications()} />
              <CurrentMode>
                Текущий режим: <span> {this.getCurrentMode()} </span>
              </CurrentMode>
            </ModeHeader>
            <div>
              {!services.isOperator() && (
                <ControlBlocks>
                  <ControlBlock>
                    <ControlBlockButtons>
                      <BigOpenGateButton
                        disabled={!rightControl}
                        onClick={() => this.openWithConfirm('allow_from_main')}
                      >
                        <img src="/images/open-gate.png" alt="" />
                        Разрешить проезд
                      </BigOpenGateButton>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <OpenGateButton disabled={!rightControl} onClick={() => this.openWithConfirm('open_main')}>
                          <img src="/images/open-gate.png" alt="" />
                          Открыть
                        </OpenGateButton>
                        <CloseGateButton
                          disabled={!rightControl}
                          onClick={() => this.props.sendControlMsg('close_main')}
                        >
                          <img src="/images/close-gate.png" alt="" />
                          Закрыть
                        </CloseGateButton>
                      </div>
                    </ControlBlockButtons>
                  </ControlBlock>
                  {this.props.data.barriersCount === 2 ? (
                    <ControlBlock className="gate">
                      <ControlBlockHeader className="gate__title">Шлюз</ControlBlockHeader>
                      <ControlBlockButtons>
                        <BigOpenGateButton
                          disabled={!rightControl}
                          onClick={() => this.props.sendControlMsg('allow_from_gateway')}
                        >
                          <img src="/images/open-gate.png" alt="" />
                          Выпустить
                        </BigOpenGateButton>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <OpenGateButton
                            disabled={!rightControl}
                            onClick={() => this.props.sendControlMsg('open_gateway')}
                          >
                            <img src="/images/open-gate.png" alt="" />
                            Открыть
                          </OpenGateButton>
                          <CloseGateButton
                            disabled={!rightControl}
                            onClick={() => this.props.sendControlMsg('close_gateway')}
                          >
                            <img src="/images/close-gate.png" alt="" />
                            Закрыть
                          </CloseGateButton>
                        </div>
                      </ControlBlockButtons>
                    </ControlBlock>
                  ) : null}
                </ControlBlocks>
              )}
            </div>
          </Mode>
          {this.state.showConfirm && <ConfirmWithComment close={this.closeConfirm} action={this.state.action} />}
        </StyledControlPanel>
        <RackState sendControlMsg={this.props.sendControlMsg} id={this.props.id} />
      </RackWrapper>
    );
  }
}

const rackSettings = {
  type: 'rack',
  reloadType: 'reboot controller',
};

export default withRackLogic(
  connect(null, {
    //setPaymentMeanInfo,
    //removePaymentMeanInfo
  })(RackControl),
  rackSettings
);

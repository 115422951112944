import React, { Fragment } from 'react';

import withRackLogic from '../racksControl/withRackLogic';
import { Wrapper, Content, Buttons, Title } from './options.styled';
import { GrayButton, RedButton } from '../../../shared/buttons';
import Reload from './reload';

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = { confirm: false };
  }

  componentDidMount = () => {
    this._ismounted = true;

    this.props.sendControlMsg('get_state');
  };

  componentWillUnmount = () => {
    this._ismounted = false;
  };

  showConfirm = () => {
    this.setState({ confirm: true });
  };

  closeConfirm = () => {
    this.setState({ confirm: false });
  };

  closeShift = () => {
    this.props.sendControlMsg('zreport_to_buffer', () => {
      this.setState({ confirm: false });
      this.props.sendControlMsg('get_state');
    });
  };

  render() {
    const { confirm } = this.state;
    const { status, id } = this.props;
    const closed = status.flags ? status.flags.includes('cash_register.shift_closed') : true;
    //console.log('🚀 ~ render ~ status', closed, status);
    const { mode } = status;
    //console.log('🚀 ~ render ~ status', status);
    const offline = mode === 'offline';
    let cl2 = offline || !mode ? 'offline1' : '';

    return (
      <Wrapper>
        <Content className={cl2}>
          {confirm ? (
            <Fragment>
              <Title>Кассовая смена будет закрыта. Продолжить?</Title>
              <Buttons>
                <GrayButton className="btn" onClick={this.closeConfirm}>
                  Отмена
                </GrayButton>
                <RedButton className="btn" onClick={this.closeShift}>
                  &nbsp; &nbsp; Ок &nbsp; &nbsp;
                </RedButton>
              </Buttons>
            </Fragment>
          ) : (
            <Buttons>
              <GrayButton disabled={closed} className="btn" onClick={this.showConfirm}>
                Закрыть смену
              </GrayButton>
            </Buttons>
          )}
          <Reload id={id} />
        </Content>
      </Wrapper>
    );
  }
}

const rackSettings = {
  type: 'app',
  reloadType: 'reboot controller',
};

export default withRackLogic(Options, rackSettings);

import React from 'react';
import { connect } from 'react-redux';

import { openWindow } from '../../../store/actions/windows';

import Warning from '../../events/Warning';
import Error from '../../events/Error';
//import Signal from '../../events/Signal'
import Call from './Call';
import services from '../../../services';
import { RackWrapper, Barrier, IconTitle, Id } from './Rack.styled';

function Rack(props) {
  //console.log('🚀 ~ Rack ~ props', props);
  function getIcon() {
    switch (props.type) {
      case 'single_rack':
        return <img src={'/images/single_rack.png'} alt="" />;
      case 'double_rack':
        return <img src={'/images/double_rack.png'} alt="" />;
      case 'payment_terminal':
        return <img src={'/images/payment_terminal.png'} alt="" />;
      case 'cashbox':
        return <img src={'/images/cashbox-icon.png'} alt="" />;
      case 'server':
        return <img src={'/images/data-server.png'} alt="" />;
      case 'goose':
        return <img src={'/images/goose.svg'} alt="" />;
      default:
        throw new Error('Rack type incorrect');
    }
  }

  function getBarriers() {
    switch (props.barriersCount) {
      case 2:
        return [<Barrier key="br_1" />, <Barrier key="br_2" />];
      case 1:
        return <Barrier />;
      case 0:
        return null;
      default:
        throw new Error('Wrong number of barriers');
    }
  }

  function showWindow() {
    props.openWindow({
      id: props.id,
      type: 'rack',
      data: { id: props.id, type: props.type, barriersCount: props.barriersCount, withSignals: !!props.events.length },
      header: services.getNodeTitle(props.type, props.id, props.caption),
    });
  }

  function showEvent() {
    const events = [...props.events];
    events.sort((first, second) => {
      if (first.type === 'critical' && second.type === 'notification') {
        return -1;
      }
      if (first.type === 'notification' && second.type === 'critical') {
        return 1;
      }
      if ((first.type === 'critical' && second.type === 'info') || (first.type === 'notification' && second.type === 'info')) {
        return -1;
      }
      if ((first.type === 'info' && second.type === 'critical') || (first.type === 'info' && second.type === 'notification')) {
        return 1;
      }

      return 0;
    });

    const event = events[0];
    let Component = null;
    switch (event.type) {
      case 'critical':
        Component = Error;
        break;
      case 'notification':
        Component = Warning;
        break;
      default:
        return null;
    }
    return <Component key={'ev_' + event.id} data={event} showWindow={showWindow} />;
  }

  function getViewRackId() {
    //console.log(props)
    if (props.caption) {
      return props.caption;
    }
    return props.type === 'cashbox' ? '' : window.service.call('getEquipmentInnerId', props.id);
  }

  function showCall() {
    return <Call info={props.call} />;
  }

  const cl = props.type;

  return (
    <RackWrapper>
      <RackWrapper onClick={() => showWindow()}>
        {props.barriersPosition === 'top' ? getBarriers() : null}
        <IconTitle className={cl}>
          {getIcon()}
          <Id className={cl}>{getViewRackId()}</Id>
        </IconTitle>
        {props.barriersPosition === 'bottom' ? getBarriers() : null}
      </RackWrapper>
      {props.events.length ? showEvent() : null}
      {props.call ? showCall() : null}
    </RackWrapper>
  );
}

const mapStateToProps = (store, props) => {
  const events = [];
  const rackEvents = store.events.events[props.id];
  for (let key in rackEvents) {
    let event = rackEvents[key];
    events.push(event);
  }

  return {
    events: events,
    call: store.calls.calls[props.id],
  };
};

export default connect(mapStateToProps, {
  openWindow,
})(Rack);

import { SET_EVENT, DEACTIVATE_EVENT, SET_EVENTS_LIST, DEACTIVATE_ALL_EVENTS, SET_EVENT_UNRECOGNIZED } from '../constants'

export const setEventsList = (events) => {
  return{
    type: SET_EVENTS_LIST,
    data: events
  }
}

export const setEvent = (data) => {
  return{
    type: SET_EVENT,
    event: data
  }
}

export const deactivateEvent = (rackId, eventId) => {
  return{
    type: DEACTIVATE_EVENT,
    rackId: rackId,
    eventId: eventId
  }
}

export const deactivateAllEvents = (rackId) => {
  return{
    type: DEACTIVATE_ALL_EVENTS,
    rackId: rackId
  }
}

export const setUnrecognaized = (event) => {
  return{
    type: SET_EVENT_UNRECOGNIZED,
    event: event
  }
}

import { SET_CALL, SET_CALL_ACTIVE, DROP_CALL } from "../constants";

export default (state, action) => {
  const defaultState = {
    calls: {},
  };
  if (!state) {
    state = defaultState;
  }
  let newCalls = { ...state.calls };
  let node;
  switch (action.type) {
    case SET_CALL:
      newCalls[action.data.source_inner_id] = {
        status: "wait",
        ...action.data,
      };
      //console.log('🚀 ~ SET_CALL', newCalls[action.data.source_inner_id]);

      return {
        ...state,
        calls: newCalls,
      };
    case SET_CALL_ACTIVE:
      if (typeof action.data === "string") {
        //console.log("🚀 ~ SET_CALL_ACTIVE", action.data);
        let call = state.calls[action.data];
        if (!call) {
          //console.log('!!!!', action.data)
          node = window.config.nodes[action.data];
          call = {};
          call.source = node.phone; //action.data.length === 5 ? window.config.app_asterisk_id_map[action.data] : window.config.rack_asterisk_id_map[action.data]
          call.source_inner_id = action.data;
          call.id = action.data.id;
        }
        const out = {
          calls: {
            ...state.calls,
            [action.data]: {
              ...call,
              status: "active",
            },
          },
        };
        //console.log("🚀 ~ out1", out);
        return out;
      } else {
        let calls = {};
        action.data.forEach((item) => {
          calls[item.source_inner_id] = {
            source: item.source,
            source_inner_id: item.source_inner_id,
            status: "active",
            id: item.id,
          };
        });
        const out = {
          calls: {
            ...state.calls,
            ...calls,
          },
        };
        //console.log("🚀 ~ out2", out);
        return out;
      }
    case DROP_CALL:
      newCalls = { ...state.calls };
      const id = action.data;
      delete newCalls[id];
      window.removeActiveCall(id);
      return {
        ...state,
        calls: {
          ...newCalls,
        },
      };
    default:
      return state;
  }
};

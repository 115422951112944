import styled, { css } from 'styled-components';

export const Img = styled.div`
  margin: 0 20px 0 0;
  max-width: 450px;
  cursor: pointer;
  img{
    max-height: 280px;
    width: auto;
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &.withLoading{
    opacity: 0;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 17px;
  line-height: 1.3;
  margin: 0 0 25px;
  padding: 0 70px;
`;
export const Calls = styled.div`
  position: absolute;
  right: -20px;
  top: 5px;
`;

export const Wrapper = styled.div`
  padding: 25px;
  box-sizing: border-box;
  background: #1e2125;
  color: #fff;
  position: absolute;
  max-width: 640px;
  width: 100%;
  left: 50%;
  top: 5%;
  margin: 0px 0 0 -320px;
  border-radius: 4px;
  border: solid 2px #ff8322;
  z-index: 100000;
  ${(props) =>
    props.double &&
    css`
      max-width: 1000px;
      margin: 0 0 0 -600px;
    `}
  &.withLoading {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, .8) url('/images/loader.svg') no-repeat 50%;
      background-size: 60px auto;
    }
  }
`;

export const VideoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 279px;
  overflow: hidden;
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    font-size: 20px;
    line-height: normal;
    padding: 7px 15px;
    height: auto;
  }
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';

//import { setArchivedEvents } from './store/actions/eventsHistory';
import { setEvent, setEventsList, setUnrecognaized } from './store/actions/events';
import { addWidget, removeWidget } from './store/actions/widgets';
import { openWindow } from './store/actions/windows';
import { setCallActive, setCall, dropCall } from './store/actions/calls';
import { updateStatus, updateStatusBg } from './store/actions/equipment';
import { setBoardsData } from './store/actions/boards';
import services from './services';
import { AppSocket } from 'app-socket';
import Windows from './components/WindowsController';
import Widgets from './components/WidgetsController';
import Sounds from './components/SoundsController';
import Unrecognaized from './components/Unrecognaized';
import TopBar from './components/TopBar';
import ZonesMap from './components/maps/ZonesMap';

class AppLogined extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    console.log('🚀 ~ app logined');

    // load active calls from localstorage ??
    window.loadActiveCalls();

    // init socket
    this.initSocket();

    // start
    window.clearActiveCalls();

    // open windows
    this.checkOpenWindows();

    this.setState({ ready: true });
  }

  /**
   * init socket
   */
  initSocket = () => {
    const {
      setEvent,
      setCallActive,
      addWidget,
      removeWidget,
      openWindow,
      setCall,
      dropCall,
      updateStatus,
      updateStatusBg,
      setEventsList,
      setBoardsData,
      setUnrecognaized,
      calls,
      unrecognized,
    } = this.props;
    const params = {
      setEvent,
      setCallActive,
      addWidget,
      removeWidget,
      openWindow,
      setCall,
      dropCall,
      updateStatus,
      updateStatusBg,
      setEventsList,
      setBoardsData,
      setUnrecognaized,
      calls,
      unrecognized,
    };
    this.appSocket = new AppSocket(params);
  };

  /**
   * check for open windows by external params
   */
  checkOpenWindows = () => {
    // open windows on external parameters
    if (window.rack_id) {
      const node = window.config.nodes[window.rack_id];
      if (node) {
        const title = services.getNodeTitle(node.type, node.id, node.caption);
        this.props.openWindow({
          id: node.id,
          type: 'rack',
          data: {
            id: node.id,
            type: node.type,
            barriersCount: node.barriersCount,
          },
          header: title,
        });
      }
      window.rack_id = null;
    }
    if (window.zone_id) {
      const node = window.config.objects.zones.find((item) => {
        return item.zoneId.toString() === window.zone_id.toString();
      });
      //console.log('node',node);
      if (node) {
        this.props.openWindow({
          id: '0',
          type: 'single_board',
          data: { zoneId: node.zoneId, color: node.color },
        });
      }
      window.zone_id = null;
    }
  };

  /**
   * render
   * @returns
   */
  render() {
    if (!this.state.ready) {
      return null;
    }
    const { unrecognized } = this.props;
    const withUnrecognized = unrecognized?.source !== undefined;

    //console.log('🚀 ~ render ~ window.socket:', window.socket);

    return (
      <>
        <TopBar />
        <div className="main">
          <ZonesMap />
          <Windows />
          <Widgets />
          <Sounds />
          {withUnrecognized && <Unrecognaized dropCall={this.props.dropCall} data={unrecognized} id={unrecognized.source} />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    calls: store.calls.calls,
    unrecognized: store.events.unrecognized,
  };
};

export default connect(mapStateToProps, {
  addWidget,
  removeWidget,
  openWindow,
  setBoardsData,
  updateStatus,
  updateStatusBg,
  setEvent,
  setEventsList,
  setCall,
  dropCall,
  setCallActive,
  setUnrecognaized,
  //setArchivedEvents,
})(AppLogined);

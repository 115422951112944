import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  background: #1e2227;
  padding: 10px;
`

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & :last-child{
    //margin-left: auto;
  }
`;
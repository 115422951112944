import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import services from 'services';
import { user } from 'services/user';
//import Video from './windows/racks/racksControl/Video';
import { BlueButton, RedButton } from './shared/buttons';
import RackVideos from 'components/windows/racks/racksControl/RackVideos';
//import withRackLogic from 'components/windows/racks/racksControl/withRackLogic';
import { CallMakeButton, CallDropButton } from 'components/windows/racks/racksControl/styledComponents';
import { setUnrecognaized } from 'store/actions/events';
import { openWindow } from 'store/actions/windows';
import { Img, Title, Buttons, Calls, VideoBlock, Wrapper, Center } from './Unrecognaized.styled';

class Unrecognaized extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cams: [],
      foto: null,
      loading: true,
    };

    this.inited = false;
  }

  componentDidMount() {
    socket.on('get_video_urls', this.prepareVideoUrls);
    socket.on('stays.get_by_id', this.prepareFoto);
    this.getVideos();
  }

  componentWillUnmount() {
    socket.off('get_video_urls', this.prepareVideoUrls);
    socket.off('stays.get_by_id', this.prepareFoto);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getVideos();
    }
  }

  prepareVideoUrls = (msg) => {
    console.log('🚀 socket <- get_video_urls', msg);
    const withStay = this.withStay();
    if (msg.target === this.props.id) {
      const out = { cams: [services.prepareVideoUrls(msg)[0]] };
      if (!withStay) {
        out.loading = false;
      }
      this.setState(out);
    }
  };

  prepareFoto = (msg) => {
    console.log('🚀 socket <- stays.get_by_id', msg);
    this.setState({ foto: this.getFotoUrl(msg), loading: false });
  };

  getVideos = () => {
    console.log('🚀 ~ unrec id:', this.props.id);
    if (!this.props.id) {
      return;
    }

    // video
    const msg = { target: this.props.id, user_id: user.getId() };
    this.inited = true;
    socket.call('get_video_urls', msg);

    // foto
    const withStay = this.withStay();
    if (withStay) {
      socket.call('stays.get_by_id', { stay_id: this.props.data.data.stay_id });
    }
  };

  getFotoUrl = (msg) => {
    if (!msg || !msg.passages) {
      return '-';
    }
    const passageId = Object.keys(msg.passages)[0];
    const passage = msg.passages[passageId];
    const photos = passage.data.photos;
    const photoId = Object.keys(photos)[0];
    return window.config.network.images_url + photos[photoId];
  };

  ok = () => {
    const msg = { target: this.props.id, user_id: user.getId() };
    //socket.call('print_receipt', msg);
    socket.call('allow_payment_mean', msg);
    this.cancel();
  };

  cancel = () => {
    socket.call('confirm_event_by_id', { id: this.props.data.id });
    this.props.setUnrecognaized(null);
  };

  showFoto = () => {
    this.props.openWindow({
      id: 'foto',
      type: 'foto',
      data: this.state.foto,
      header: this.props.data.source_name,
    });
  };

  getFoto = () => {
    if (!this.props.data || !this.props.data.data) {
      return;
    }
    if (this.state.foto) {
      this.showFoto();
      return;
    }

    socket.call('stays.get_by_id', { stay_id: this.props.data.data.stay_id });
  };

  withStay = () => {
    const { data } = this.props;
    return data && data.data.stay_id;
  };

  render() {
    const { id, data } = this.props;
    const { loading, foto } = this.state;
    const withStay = this.withStay();
    if (!id) {
      return null;
    }
    let cl = loading ? 'withLoading' : '';
    const rightCall = window.haveRight('mnemo.calls');
    const msg = data.message ? data.message : 'номер распознан некорректно';
    const double = withStay && foto && foto !== '-';

    //console.log(active)
    return (
      <Wrapper double={double} className={cl}>
        <Calls>
          {rightCall && <CallMakeButton action={this.props.makeCall} src="/images/audio.png" />}
          {rightCall && this.props.call && <CallDropButton action={this.props.cancelCall} src="/images/audio-2.png" />}
        </Calls>

        <Title>
          {data.source_name}: {msg}.
          <br />
          Пропустить ТС?
        </Title>
        <Center className={cl}>
          {double && (
            <Img onClick={this.showFoto}>
              <img height={280} src={foto} alt="..." />
            </Img>
          )}
          <VideoBlock>
            <RackVideos cams={this.state.cams} id={this.props.id} cl={''} simple1 />
          </VideoBlock>
        </Center>
        <Buttons
          style={{
            display: 'flex',
            marginTop: '20px',
            justifyContent: 'center',
          }}
        >
          <RedButton onClick={this.cancel}>Запретить</RedButton> &nbsp; &nbsp; &nbsp;
          <BlueButton onClick={this.ok}>Разрешить</BlueButton>
        </Buttons>
      </Wrapper>
    );
  }
}

function mapStateToProps(__store) {
  //const data = store.events.unrecognized;
  return {
    //id: data ? data.source : null,
    //data: data,
  };
}

export default connect(mapStateToProps, { setUnrecognaized, openWindow })(Unrecognaized);

import React from 'react';
import styled from 'styled-components';

const UserWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  div {
    color: white;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 10px;
  }
  img {
    cursor: pointer;
  }
  &:hover .menu11 {
    display: block;
  }
`;

const Button = styled.button`
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #eee;
  border-radius: 16px;
  padding: 0 15px 0px 35px;
  background: #38414b url('/images/settings.svg') no-repeat 10px 50%;
  background-size: 20px;
  border: 0;
  cursor: pointer;
  margin: 0 5px 0 0;
  position: relative;
  .menu1 {
    position: absolute;
    display: none;
    right: 0;
    top: 26px;
    z-index: 10;
    padding-top: 7px;
  }
  .menu1items {
    background: #38414b;
    border-radius: 16px;
    white-space: nowrap;
    display: block;
  }
  &:hover {
    .menu1 {
      display: block;
    }
  }
`;

const Subbutton = styled.span`
  display: block;
  line-height: 1.3;
  font-size: 14px;
  color: #eee;

  padding: 8px 15px;
  background: #38414b;
  background-size: 20px;
  border: 0;
  text-align: right;
  cursor: pointer;
  margin: 0 0 1px;
  transition: 0.3s all ease-in;
  &.sb-all {
    border-radius: 16px;
  }
  &.sb-top {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  &.sb-bt {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &:hover {
    background: #18212b;
  }
`;

const User = (props) => {
  //console.log('🚀 ~ User ~ window.user', window.user);
  let name = '';
  if (window.user.info && window.user.info.config) {
    name = window.user.info.config.name;
  }

  /*
  const showPopup = () => {
    props.openWindow({
      id: 'settingswindow',
      type: 'settings',
      data: {},
      header: 'Настройка вида парковочного билета',
    });
  };
  */

  const showSIPPopup = () => {
    props.openWindow({
      id: 'settingssipwindow',
      type: 'settingssip',
      data: {},
      header: 'Настройка SIP номера',
    });
  };

  return (
    <UserWrapper>
      <Button>
        Настройки
        <span className="menu1">
          <span className="menu1items">
            {/*
            <Subbutton className="sb-top" onClick={showPopup}>
              Вид парковочного билета
            </Subbutton>*/}
            <Subbutton className="sb-all" onClick={showSIPPopup}>
              SIP номер
            </Subbutton>
          </span>
        </span>
      </Button>
      <div>{name}</div>
      <img onClick={window.user.logoutUser} src="/images/button-logout.png" alt="" />
    </UserWrapper>
  );
};

export default User;

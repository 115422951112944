import styled from 'styled-components';

const OvalButton = styled.button`
  cursor: pointer;
  border: 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #ffffff;
  font-size: 15px;
  box-shadow: 0 1px 1px 0 rgba(79, 13, 13, 0.5);
  padding: 0 10px;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const RedButton = styled(OvalButton)`
  background-image: linear-gradient(#d14d45, #d14d45), linear-gradient(to bottom, rgba(255, 127, 127, 0.5), rgba(72, 0, 0, 0.5));
`;
export const BlueButton = styled(OvalButton)`
  //background-image: linear-gradient(#3ea5ff, #3ea5ff), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #00396c 98%);
  background: #3ea5ff;
  transition: background-color 0.15s ease-in;
  &:hover {
    background: #2e95ef;
  }
  &:active {
    background: #1e85df;
  }

  &.loading::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5) url(/images/loader.svg) no-repeat 50% 50%;
    background-size: 18px auto;
  }
`;
export const WhiteButton = styled(OvalButton)`
  color: black;
  background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5));
`;
export const GrayButton = styled(OvalButton)`
  box-shadow: none;
  img {
    margin-right: 10px;
  }
  background-image: linear-gradient(#6e7884, #6e7884), linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5));
  position: relative;
  &.loading::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5) url(/images/loader.svg) no-repeat 50% 50%;
    background-size: 18px auto;
  }
`;
